<template>
  <div class="top" v-if="!isProcessing">
    <top-visual class="top__element--visual"  id="visual"/>
    <p class="top__element--about" v-html="createAutolink(about)" id="about" />
    <!-- 入会ボタン -->
    <top-registrator class="top__element--registrator" :index="0" />
    <!-- メッセージ動画 -->
    <template v-if="messageMovie">
      <top-movie class="top__element--movie" />
      <v-divider class="top__divider" />
    </template>
    <!-- 特典情報 -->
    <template v-if="privileges.length">
      <privileges class="top__element" :privileges="privileges" />
    </template>
    <!-- 入会ボタン -->
    <top-registrator class="top__element--registrator" :index="1" />
    <!-- 代表者情報 -->
    <template v-if="representatives['name'] && representatives['icon'] && representatives['description']">
      <top-manager class="top__element" />
      <v-divider class="top__divider" />
    </template>
    <!-- FAQ -->
    <template v-if="faqs.length">
      <top-qa class="top__element" />
      <v-divider class="top__divider" />
    </template>
  </div>
</template>

<script>
// 動画の種類
const MOVIE_TYPE = 'message'

import url from '@/assets/lib/url'

import TopMovie from '@/components/top/movie'
import Privileges from '@/components/common/privileges'
import TopManager from '@/components/top/manager'
import TopQa from '@/components/top/qa'
import TopRegistrator from '@/components/top/registrator'
import TopVisual from '@/components/top/visual'

export default {
  name: 'top',
  mixins: [url],
  components: { TopVisual, TopRegistrator, TopMovie, Privileges, TopManager, TopQa },
  async mounted () {
    const promises = []
    // トップ画像が未取得なら取得
    if (!this.visualImage) promises.push(this.$store.dispatch('settingImages/getSettingImage', 'visual'))
    // 特典画像が未取得なら取得
    if (this.privileges.length === 0) promises.push(this.$store.dispatch('settingPrivileges/getSettingPrivileges'))
    // 基本情報の説明文と運営名が未取得なら取得
    const generalKeys = ['about', 'companyName']
    for (const key of generalKeys) {
      if (!this.general[key]) promises.push(this.$store.dispatch('settingGeneral/getSettingGeneral', key))
    }
    // 代表者情報の代表者名と画像と説明が未取得なら取得
    const representativeKeys = ['name', 'icon', 'description']
    for (const key of representativeKeys) {
      if (!this.representatives[key]) promises.push(this.$store.dispatch('settingRepresentatives/getSettingRepresentative', key))
    }
    // FAQが未取得なら取得
    if (this.faqs.length === 0) promises.push(this.$store.dispatch('faqs/getFaqs'))
    // メッセージ動画の設定情報の取得
    if (!this.messageMovie) promises.push(this.$store.dispatch('settingMovies/getSettingMovie', MOVIE_TYPE))
    // ボタンの設定情報の取得
    const types = ['button', 'supplementTop']
    for (const type of types) {
      if (!this.buttonInfos[type]) promises.push(this.$store.dispatch('settingButtons/getSettingButton', { type: type, category: 'registrator' }))
    }
    await Promise.all(promises)

    this.$store.commit('setProcessing', false)
  },
  computed: {
    /**
     * @return {Boolean} 処理中かどうか
     */
    isProcessing () {
      return this.$store.getters.isProcessing
    },
    /**
     * @return {Object[]} 入会特典で表示するタイトルと説明文
     */
    privileges () {
      return this.$store.getters['settingPrivileges/settingPrivileges']
    },
    /**
     * @return {Object} 基本情報の全て
     */
    general () {
      return this.$store.getters['settingGeneral/settingGeneralAll']
    },
    /**
     * @return {String} サービスの説明文
     */
    about () {
      const data = this.$store.getters['settingGeneral/settingGeneral']('about')
      return data ? data.value : null
    },
    /**
     * @return {Object} 代表者情報の全て
     */
    representatives () {
      return this.$store.getters['settingRepresentatives/settingRepresentativeAll']
    },
    /**
     * @return {Object[]} faq情報
     */
    faqs () {
      return this.$store.getters['faqs/faqs']
    },
    /**
     * @return {Object} メッセージ動画の設定情報
     */
    messageMovie () {
      return this.$store.getters['settingMovies/settingMovie'](MOVIE_TYPE)
    },
    /**
     * @return {Object} ボタンの設定情報
     */
    buttonInfos () {
      return this.$store.getters['settingButtons/settingButtons']
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/sass/size.scss";
@import "@/assets/sass/color.scss";

.top {
  position: relative;
  width: 100vw;
  max-width: $max_width;
  min-height: 100vh;
  padding: 0 $padding_width;
  margin: $header_height auto 0;
  text-align: center;
  &__element {
    padding: 0;
    margin: 0;
    &--registrator {
      @extend .top__element;
      margin: 120px 0;
    }
    &--about {
      @extend .top__element;
      margin-top: 40px;
      font-size: 1.6rem;
      text-align: left;
      word-break: break-all;
      white-space: pre-wrap;
    }
    &--movie {
      @extend .top__element;
    }
    &--visual {
      @extend .top__element;
      margin: 0 -#{$padding_width};
    }
  }
  &__divider {
    max-width: 100vw;
    margin: 60px -#{$padding_width};
    border-color: $gray_lighten_color;

    // 要素が2個以上で、かつ最後の要素はdividerを表示しない
    &:nth-of-type(n + 2):last-of-type {
      display: none;
    }
  }
}
</style>
