<template>
  <div class="top-manager">
    <v-img class="top-manager__icon" :src="representativeIcon"/>
    <section class="top-manager__text">
      <label class="top-manager__text__label">運営の代表者</label>
      <p class="top-manager__text__name">{{ representativeName }}<span class="top-manager__text__name__company">（{{ companyName }}）</span></p>
      <p class="top-manager__text__description"  v-html="createAutolink(representativeDescription)" />
    </section>
  </div>
</template>

<script>
import url from '@/assets/lib/url'
export default {
  mixins: [url],
  computed: {
    /**
     * @return {String} 運営の名前
     */
    companyName () {
      const data = this.$store.getters['settingGeneral/settingGeneral']('companyName')
      return data ? data.value : null
    },
    /**
     * @return {Object} 代表者の名前
     */
    representativeName () {
      const data = this.$store.getters['settingRepresentatives/settingRepresentative']('name')
      return data ? data.value : null
    },
    /**
     * @return {Object} 代表者の画像URL
     */
    representativeIcon () {
      const data = this.$store.getters['settingRepresentatives/settingRepresentative']('icon')
      return data ? data.value : null
    },
    /**
     * @return {Object} 代表者の説明
     */
    representativeDescription () {
      const data = this.$store.getters['settingRepresentatives/settingRepresentative']('description')
      return data ? data.value : null
    }
  }
}
</script>
<style lang="scss" scoped>
@import "@/assets/sass/size.scss";
@import "@/assets/sass/color.scss";

.top-manager {
  display: flex;
  flex-flow: row, wrap;
  align-items: center;
  max-width: $max_width;
  margin-left: -#{$padding_width};
  &__icon {
    flex-grow: 0;
    width: 100px;
    height: 100px;
    margin-right: 40px;
    background-color: $gray_lighten_color;
    border-radius: 50%;

    @media (max-width: $max_mobile_size) {
      margin: 0 auto 10px;
    }
  }
  &__text {
    flex: 1;
    min-width: 0;
    text-align: left;

    @media (max-width: $max_mobile_size) {
      flex: auto;
      max-width: 100%;
      text-align: center;
    }
    &__label {
      display: block;
      margin-bottom: 5px;
      font-size: 1.4rem;
      color: $gray_color;
    }
    &__name {
      margin: 0 auto;
      font-size: 1.8rem;
      font-weight: bold;
      &__company {
        margin: 0 auto;
        font-size: 1.2rem;
        font-weight: normal;
        color: $gray_color;
      }
    }
    &__description {
      margin: 20px 0 0;
      font-size: 1.5rem;
      text-align: left;
      word-wrap: break-word;
      white-space: pre-wrap;
    }
  }
}
</style>
