<template>
  <div class="top-qa">
    <h1 class="top-qa__title">よくある質問</h1>
    <h2 class="top-qa__subtitle">FAQ</h2>
    <qa-list class="top-qa__faqs" v-for="faq in faqs.slice(0, 5)" :key="faq.faqid"
             :faq="faq" :isShow="faq.faqid === openedFaqID" @set-opened-faq-id="setOpenedFaqID" />
    <router-link class="top-qa__link" :to="{ name: 'faq' }" v-if="faqs.length > 5">全ての質問を見る</router-link>
  </div>
</template>
<script>
import QaList from '@/components/common/qa'

export default {
  components: { QaList },
  data () {
    return {
      // 回答を開いているFAQのドキュメントID（開いてない場合はnull）
      openedFaqID: null
    }
  },
  computed: {
    /**
     * @return {Object[]} faq情報
     */
    faqs () {
      return this.$store.getters['faqs/faqs']
    }
  },
  methods: {
    /**
     * 回答を開いたFAQのドキュメントIDをセット（閉じる場合はnull）
     * @param {String} id 回答を開いたFAQのドキュメントID
     */
    setOpenedFaqID (id) {
      this.openedFaqID = id
    }
  }
}
</script>
<style lang="scss" scoped>
@import "@/assets/sass/color.scss";
@import "@/assets/sass/size.scss";

.top-qa {
  &__title {
    margin: 0;
  }
  &__subtitle {
    margin-bottom: 20px;
    color: $concept_color;
  }
  &__faqs {
    margin: 10px 0;
  }
  &__link {
    display: block;
    font-size: 1.2rem;
    text-align: right;
  }
}

</style>
