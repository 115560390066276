<template>
  <div class="privileges">
    <div class="privileges__list" v-for="item in privileges" :key="item.rank">
      <h2 class="privileges__list__title">特徴 {{ encircledNumber(item.rank) }}</h2>
      <p class="privileges__list__appeal">
        <span class="privileges__list__appeal__underline">{{ item.title }}</span>
      </p>
      <p class="privileges__list__description" v-html="createAutolink(item.description)" />
      <v-carousel class="privileges__list__carousel" v-if="item.images.length > 0" cycle interval="4000" hide-delimiter-background show-arrows-on-hover :show-arrows="item.images.length > 1" :hide-delimiters="!(item.images.length > 1)">
        <!-- v-carouselのみだと初回アクセス時画像切り替えごとに画像を読み込みチラつくのでv-imgをネストしてeager属性を付けて先に読み込ませる -->
        <v-carousel-item class="privileges__list__carousel__item" eager v-for="img in item.images" :key="img" >
          <v-img class="privileges__list__carousel__item__img" :src="img" eager />
        </v-carousel-item>
      </v-carousel>
    </div>
  </div>
</template>

<script>
import string from '@/assets/lib/string'
import url from '@/assets/lib/url'

export default {
  mixins: [string, url],
  props: {
    // 特徴一覧
    privileges: {
      type: Array,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/sass/size.scss";
@import "@/assets/sass/color.scss";

.privileges {
  text-align: center;
  &__list {
    margin-top: 100px;
    &:nth-of-type(1) {
      margin-top: 0;
    }
    &__title {
      font-size: 1.4rem;
      line-height: 1.5rem;
    }
    &__appeal {
      margin: $unit_size auto 0;
      font-size: 2rem;
      font-weight: bold;
      text-align: center;
      &__underline {
        display: inline;

        // linear-gradientのパーセンテージは以下の計算式
        // 20px(フォントサイズ) * パーセンテージ = 5px(Figma上の線の高さ)
        // パーセンテージ = 5 / 20 = 0.25 = 25%
        background: linear-gradient(0deg, $concept_transparent_color 25%, transparent 25%);
      }
    }
    &__description {
      margin: $unit_size*2 auto 0;
      font-size: 1.5rem;
      text-align: justify;
      white-space: pre-wrap;
    }
    &__carousel {
      width: 100%;
      height: 298px !important;
      margin-top: $unit_size;

      @media (max-width: $max_width) {
        height: calc((100vw - #{$padding_width} * 2) * 100 / 191) !important;
      }
      & ::v-deep .v-carousel__controls {
        height: 25px;
        & .v-btn--icon {
          width: 5px;
          height: 25px;
          margin: 0 1px;
        }
        & .v-icon {
          opacity: 1;
          &::before {
            width: 5px;
            height: 5px;
            content: "";
            background-color: $white_color;
            border-radius: 50%;
          }
        }
        & .v-btn--active {
          &::before {
            opacity: 0;
          }
          & .v-icon::before {
            background-color: $gray_color;
          }
        }
      }
      &__item {
        & ::v-deep .v-carousel__item {
          width: 100%;
          height: 298px !important;
        }
        &__img {
          width: 100%;
          height: 298px !important;

          @media (max-width: $max_width) {
            height: calc((100vw - #{$padding_width} * 2) * 100 / 191) !important;
          }
        }
      }
    }
  }
}
</style>
