<template>
  <div class="top-movie">
    <h1 class="top-movie__title" v-if="messageMovie.title">{{ messageMovie.title }}</h1>
    <p class="top-movie__description" v-if="messageMovie.description" >{{ messageMovie.description }}</p>
    <div class="top-movie__movie">
      <iframe class="top-movie__movie__content" :src="convertEmbedURL(messageMovie.url)" :title="messageMovie.title" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" />
    </div>
  </div>
</template>

<script>
// 動画の種類
const MOVIE_TYPE = 'message'

import libURL from '@/assets/lib/url'
export default {
  mixins: [libURL],
  computed: {
    /**
     * @return {Object} メッセージ動画の設定情報
     */
    messageMovie () {
      return this.$store.getters['settingMovies/settingMovie'](MOVIE_TYPE)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/sass/color.scss";

.top-movie {
  &__title {
    color: $concept_color;
  }
  &__description {
    margin: 10px 0 0;
    font-size: 1.6rem;
    text-align: left;
  }
  &__movie {
    // @see アスペクト比の調整：https://blog-and-destroy.com/20501
    position: relative;
    width: 100%;
    padding-top: 56.25%;
    margin-top: 20px;
    background: $gray_lighten_color;
    &__content {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}
</style>
