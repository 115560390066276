<template>
  <div class="top--visual" id="visual">
    <v-img class="top--visual__background" v-if="visualImage" :src="visualImage" />
    <v-btn class="top--visual__arrow" icon depressed @click="scrollNextElm('about')">
      <v-icon class="top--visual__arrow__icon">mdi-chevron-down</v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  mounted () {
    // スマホのときはアドレスバー分高さがずれるので、修正する
    // document.getElementByIdは画面描画後じゃないとnullが返ってくるのでsetProcessing=falseの後に持ってくる
    const size = require('@/assets/sass/size.scss')
    const headerHeight = parseInt(size.header_height)
    const innerHeight = window.innerHeight
    document.getElementById('visual').style.height = (innerHeight - headerHeight) + 'px'
  },
  computed: {
    /**
     * @return {Object} top画像
     */
    visualImage () {
      const data = this.$store.getters['settingImages/settingImage']('visual')
      return data ? data.icon : null
    }
  },
  methods: {
    /**
     * 画面を指定のIDの位置までスクロール
     * @param {String} elementId スクロールしたいエレメントのID
     */
    scrollNextElm (elementId) {
      const el = document.getElementById(elementId)
      window.scrollTo({ top: el.offsetTop - 30, behavior: 'smooth' })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/sass/size.scss";
@import "@/assets/sass/color.scss";

.top--visual {
  position: relative;
  width: 100vw;
  max-width: $max_width;
  height: calc(100vh - #{$header_height});
  background-color: #829837;
  &__background {
    height: 100%;
  }
  &__arrow {
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 80px;
    height: 80px;
    transform: translate(-50%, 0);
    &__icon {
      &.v-icon {
        font-size: 8rem;
        color: rgba($color:$white_color, $alpha: 50%);
      }
    }
  }
}
</style>
