<template>
  <div class="top-registrator">
    <p class="top-registrator__text" v-if="supplementTopTexts">{{ supplementTopTexts }}</p>
    <v-btn class="top-registrator__btn" depressed @click="$router.push({ name: 'signup' })">
      <span class="top-registrator__btn__text">{{ buttonTexts }}</span>
      <v-icon class="top-registrator__btn__icon">arrow_forward_ios</v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  props: {
    // ボタンのインデックス
    index: {
      type: Number,
      required: true
    }
  },
  computed: {
    /**
     * @return {Object} ボタン内テキスト
     */
    buttonTexts () {
      const data = this.$store.getters['settingButtons/settingButton']('button')
      const texts = data ? data.texts[this.index] : null
      return texts ? texts : '入会する'
    },
    /**
     * @return {Object} ボタン上部補足テキスト
     */
    supplementTopTexts () {
      const data = this.$store.getters['settingButtons/settingButton']('supplementTop')
      const texts = data ? data.texts[this.index] : null
      return texts ? texts : ''
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/sass/size.scss";
@import "@/assets/sass/color.scss";

.top-registrator {
  &__text {
    margin-bottom: 10px;
    font-size: 1.6rem;
    text-align: center;
  }
  &__btn {
    display: flex;
    width: 100%;
    max-width: 380px;
    margin: 0 auto;
    font-size: 1.8rem;
    font-weight: bold;
    color: $white_color;
    text-transform: none;
    word-break: break-all;
    white-space: pre-line;
    border-radius: 70px;
    box-shadow: 0 1px 1px rgb(0 0 0 / 25%);
    &.v-btn:not(.v-btn--round).v-size--default {
      min-width: auto;
      height: auto;
      padding: $unit_size*2 $unit_size*3;
    }
    &.theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
      background-color: $concept_color;
    }
    &__text {
      flex: 1;
      line-height: 2.4rem;
    }
    &__icon {
      flex: initial;
    }
  }
}
</style>
